:root {
  --primary: #13c79f;
  --primary-light: #b5f0e3;
  --accent: #f2c14e;
  --accent-light: #f8e4b5;
  --dark-grey: #3b3b3b;
  --light-grey: #b3b3b3;
  --background: #ffffff;

  --cast-purple: hsl(276, 83%, 43%);
  --cast-yellow: hsl(60, 83%, 43%);
  --cast-gold: hsl(46, 83%, 43%);
}
