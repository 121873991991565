@import './resets';
@import './variables';
@import './type';
@import './layout';
@import './navbar';
@import './footer';

.header {
  --header-background: var(--background);
  --header-title-color: var(--primary);
  --header-subtitle-color: var(--dark-grey);

  padding: 10vh 0 6vh 0;
  background-color: var(--header-background);
  text-align: center;

  object {
    display: block;
    width: 90%;
    max-width: 840px;
    margin: auto auto 10vh auto;
  }

  h1 {
    color: var(--header-title-color);
    padding-bottom: 10px;
  }

  p {
    color: var(--header-subtitle-color);
  }

  &.text-only {
    padding: 10vh 8vw;
  }
}

article {
  padding: 100px 95px;

  section {
    padding-bottom: 50px;

    &:last-child {
      padding-bottom: 0px;
    }

    a {
      color: var(--primary);
      text-decoration: none;
    }
  }

  @media screen and (max-width: 760px) {
    padding: 60px 8vw;
  }
}

.home-intro {
  width: 100%;
  height: auto;
  padding: 80px 95px;
  background: var(--primary);

  .inner {
    display: block;
    width: 100%;
  }

  @media screen and (max-width: 760px) {
    padding: 50px 8vw;
  }
}

.home-cards-wrapper {
  width: 100%;
  height: auto;
  padding: 130px 95px;

  @media screen and (max-width: 760px) {
    padding: 60px 8vw;
  }
}

.pricing-cards {
  padding: 20px 0;
}

hr {
  width: calc(100% - (95px * 2));
  margin: 0 auto;
  border: none;
  height: 2px;
  background-color: var(--accent-light);
}

details {
  overflow: hidden;
  margin: 0;
  padding: 22px 0 0 0;

  summary {
    padding: 20px 32px;
    color: var(--dark-grey);
    border: 3px solid #f2c14e;
    box-shadow: 0px 0px 8px 3px rgba(59, 59, 59, 0.12);
    border-radius: 10px;
    display: block;
    cursor: pointer;
    position: relative;
    padding-right: 73px;

    h5 {
      color: var(--dark-grey);
      font-size: 1.1em;
      font-weight: 400;
    }

    &::after {
      content: '';
      display: block;
      width: 30px;
      height: 30px;
      border: solid 4px var(--accent);
      position: absolute;
      transform: rotate(45deg);
      right: 32px;
      top: 50%;
      margin-top: -22px;
      border-top: none;
      border-left: none;
    }
  }

  &[open] summary::after {
    transform: rotate(-135deg);
    margin-top: -10px;
  }

  .accordion-content {
    padding: 22px 32px 10px 32px;

    a {
      color: var(--primary);
      text-decoration: none;
    }
  }
}

.form-control {
  padding-bottom: 40px;

  label {
    span {
      display: block;
      padding-bottom: 5px;
      font-weight: 300;
      color: var(--dark-grey);
    }

    input {
      height: 60px;
      display: block;
      width: 100%;
      border: solid 1px var(--light-grey);
      text-indent: 10px;
      font-size: 32px;
      font-family: 'Nunito Sans', sans-serif;
      font-weight: 300;
      color: var(--dark-grey);

      &::placeholder {
        color: var(--light-grey);
      }
    }

    textarea {
      display: block;
      width: 100%;
      height: auto;
      min-height: 200px;
      resize: vertical;
      padding: 10px;
      font-size: 32px;
      font-family: 'Nunito Sans', sans-serif;
      color: var(--dark-grey);
      border: solid 1px var(--light-grey);
      font-weight: 300;

      &::placeholder {
        color: var(--background);
      }
    }

    &:focus-within {
      span {
        color: var(--primary);
      }

      input,
      textarea {
        border-color: var(--primary-light);
      }
    }
  }
}

.form-row {
  @media screen and (min-width: 1200px) {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    width: calc(100% + 40px);

    .form-control {
      width: calc(50% - 40px);
      max-width: calc(50% - 40px);
      margin-right: 40px;
    }
  }
}

.button button {
  display: inline-block;
  border: none;
  line-height: 45px;
  padding: 0 50px;
  background-color: var(--accent);
  border-radius: 10px;
  cursor: pointer;
  font-weight: 300;
  font-size: 20px;
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: var(--background);
  font-family: 'Nunito Sans', sans-serif;
}

