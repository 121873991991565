:root {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 18px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  --font-color: var(--dark-grey);

  margin: 0;
  padding: 0;
  color: var(--font-color);

  &.light {
    --font-color: var(--light-grey);
  }

  &.background {
    --font-color: var(--background);
  }
}

h1 {
  font-size: 3.5em;
  font-weight: 400;

  @media screen and (max-width: 470px) {
    font-size: 2em;
  }
}

h2 {
  font-size: 3em;
  font-weight: 400;

  @media screen and (max-width: 470px) {
    font-size: 1.9em;
  }
}

h2 {
  font-size: 2.4em;
  font-weight: 400;

  @media screen and (max-width: 470px) {
    font-size: 1.75em;
  }
}

h3 {
  font-size: 2em;
  font-weight: 400;

  @media screen and (max-width: 470px) {
    font-size: 1.5em;
  }
}

h4 {
  font-size: 1.8em;
  font-weight: 400;

  @media screen and (max-width: 470px) {
    font-size: 1.35em;
  }
}

h5 {
  font-size: 1.5em;
  font-weight: 400;

  @media screen and (max-width: 470px) {
    font-size: 1.1em;
  }
}

p,
h6 {
  font-size: 1em;
}

p {
  font-weight: 300;
}

h6 {
  font-weight: 600;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}
