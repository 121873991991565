.card-wrapper {
  width: calc(100% + 40px);
  height: auto;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-flow: row nowrap;
  gap: 40px;

  .card {
    display: flex;
    flex-flow: column nowrap;

    align-items: center;
    justify-content: space-between;

    width: calc(40% - 40px);
    min-width: calc(40% - 40px);
    border: 5px solid var(--accent);
    text-align: center;
    padding: 30px 18px;
    border-radius: 20px;
    text-decoration: none;

    h6 {
      color: var(--accent);
    }

    p {
      padding: 1em 0;
      margin-bottom: auto;
    }

    &.small {
      width: calc(30% - 40px);
      min-width: calc(30% - 40px);
    }

    &.half {
      width: calc(50% - 40px);
      min-width: calc(50% - 40px);
    }

    &[href] {
      cursor: pointer;
    }
  }

  @media screen and (max-width: 1200px) {
    display: block;
    width: 100%;

    .card,
    .card.small,
    .card.half {
      display: block;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      min-width: unset;
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.hug {
    justify-content: center;
  }
}

.legend {
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 15px 25px;
  border-radius: 5px;
  border: solid 1px var(--light-grey);
  background-color: var(--background);
  margin-bottom: 60px;

  li {
    --spot-color: var(--cast-gold);
    padding: 0 0 0 30px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      margin-top: -9px;
      background-color: var(--spot-color);
    }

    &.one {
      --spot-color: var(--cast-purple);
    }

    &.two {
      --spot-color: var(--cast-yellow);
    }
  }

  @media screen and (max-width: 450px) {
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;

    li {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}

.cast-list {
  max-width: 900px;
  margin: auto;

  .cast-item {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    height: auto;
    margin-bottom: 30px;

    .cast-item-label {
      font-size: 1.2em;
      color: var(--dark-grey);
      text-decoration: underline;
      text-decoration-color: var(--primary);
    }

    .cast-item-content {
      list-style: none;
      display: block;
      margin: 0;
      padding: 0;
      text-align: right;

      li {
        --item-color: var(--cast-gold);
        padding: 0 35px 0 15px;
        margin-bottom: 20px;
        position: relative;
        color: var(--dark-grey);

        &::after {
          content: '';
          position: absolute;
          right: 0;
          top: 50%;
          border-radius: 50%;
          width: 18px;
          height: 18px;
          margin-top: -9px;
          background-color: var(--item-color);
        }

        &.one {
          --item-color: var(--cast-purple);
        }

        &.two {
          --item-color: var(--cast-yellow);
        }
      }
    }

    @media screen and (max-width: 450px) {
      flex-flow: column nowrap;

      .cast-item-label {
        padding-bottom: 15px;
      }

      .cast-item-content {
        width: 100%;
        text-align: left;
      }
    }
  }
}

ul.pricing-table {
  display: block;
  padding: 0;
  margin: 0 auto;
  width: 90%;
  max-width: 90%;
  text-align: left;
  list-style: none;
  border: solid 1px var(--light-grey);
  border-radius: 15px;

  li {
    border-bottom: solid 1px var(--light-grey);
    padding: 15px;

    p {
      padding: 0 !important;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}

figure.play-logo {
  display: block;
  width: 90%;
  max-width: 250px;
  margin: 0 auto;
  padding: 30px 0;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

