*,
*::before,
*::after {
  box-sizing: border-box;
  outline: none;
}

body {
  padding: 0;
  margin: 0;
}
