.db-navbar {
  --navbar-font-color: var(--dark-grey);
  --navbar-background-color: var(--background);
  --navbar-logo-color: var(--primary);

  width: 100%;
  height: auto;
  padding: 0 95px;
  background-color: var(--navbar-background-color);
  position: relative;
  z-index: 100;
  border-bottom: solid 2px transparent;
  transition: border cubic-bezier(0.455, 0.03, 0.515, 0.955) 180ms;

  .db-navbar-inner {
    height: 70px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .db-logo {
      height: 70%;
      max-height: 45px;
      width: auto;
      display: block;
      text-decoration: none;

      svg {
        display: block;
        height: 100%;
        width: auto;

        path {
          fill: var(--navbar-logo-color);
        }
      }
    }

    .db-nav-links {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .db-nav-links-list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        list-style: none;
        margin: 0;
        padding: 0;

        .db-nav-links-list-item a {
          line-height: 45px;
          display: block;
          margin-left: 25px;
          text-decoration: none;
          color: var(--navbar-font-color);
        }

        .db-nav-links-list-item {
          a {
            position: relative;
            z-index: 10;

            &::after {
              content: '';
              width: calc(100% + 30px);
              height: 30px;
              background-color: var(--primary);
              color: var(--background);
              position: absolute;
              border-radius: 45px;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              z-index: -1;
              opacity: 0;
            }
          }

          &.active {
            a {
              color: var(--background);

              &::after {
                opacity: 1;
              }
            }
          }
        }
      }
    }

    .db-navbar-menu {
      display: none;
    }
  }

  @media screen and (max-width: 760px) {
    padding: 0 8vw;

    .db-navbar-inner {
      .db-nav-links {
        display: block;
        position: absolute;
        left: 0;
        height: auto;
        top: 70px;
        width: 100%;
        background: var(--navbar-background-color);
        padding: 20px;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        border: solid 2px var(--accent);
        border-top: none;
        text-align: center;
        transform-origin: top center;
        transform: scaleY(0);
        transition: transform cubic-bezier(0.455, 0.03, 0.515, 0.955) 180ms;

        .db-nav-links-list {
          display: block;
          opacity: 0;

          .db-nav-links-list-item {
            a {
              padding: 0;
              margin: 0;

              &::after {
                display: none;
              }
            }

            &.active {
              background-color: var(--primary);
            }
          }
        }
      }

      .db-navbar-menu {
        display: block;
        height: 50px;
        width: 50px;
        appearance: none;
        position: relative;
        border: none;
        background-color: var(--navbar-background-color);
        padding: 0;
        border-radius: 5px;
        border: solid 1px var(--light-grey);

        span {
          display: block;
          width: 30px;
          height: 2px;
          border-radius: 5px;
          background-color: var(--navbar-font-color);
          position: absolute;
          left: 10px;
          transition: all cubic-bezier(0.455, 0.03, 0.515, 0.955) 180ms;
          margin-top: 0;

          &:nth-child(1) {
            top: 14px;
          }

          &:nth-child(2) {
            top: 50%;
            transform: translateY(-50%);
          }

          &:nth-child(3) {
            bottom: 14px;
          }
        }

        &.open {
          span:nth-child(2) {
            opacity: 0;
          }

          span:nth-child(1),
          span:nth-child(3) {
            top: 50%;
            margin-top: -1px;
          }

          span:nth-child(1) {
            transform: rotate(-45deg);
          }

          span:nth-child(3) {
            transform: rotate(45deg);
          }
        }
      }
    }

    &.is-open {
      border-color: var(--accent);
    }

    &.is-open .db-navbar-inner .db-nav-links {
      transform: scaleY(1);

      .db-nav-links-list {
        animation: ItemsAppears cubic-bezier(0.455, 0.03, 0.515, 0.955) 220ms
          300ms forwards;
      }
    }
  }
}

@keyframes ItemsAppears {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
