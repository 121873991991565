.db-footer {
  width: 100%;

  .db-footer-border {
    svg {
      display: block;
      min-height: 80px;
      margin-bottom: -1px;
    }

    svg path {
      fill: var(--dark-grey);
    }
  }

  .db-footer-columns {
    padding: 50px 95px;
    background-color: var(--dark-grey);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .db-footer-column {
      &:first-child {
        flex-shrink: 1;
        padding-right: 20px;
      }

      &:last-child {
        flex-grow: 1;
        padding-left: 20px;
        max-width: 600px;
      }

      .db-footer-sitemap {
        list-style: none;
        display: block;
        margin: 0;
        padding: 15px 0;

        .db-footer-sitemap-link {
          a {
            font-size: 20px;
            line-height: 44px;
            color: var(--background);
            text-decoration: none;
            display: inline-block;

            &:hover {
              color: var(--primary-light);
            }
          }
        }
      }
    }

    @media screen and (max-width: 760px) {
      padding: 30px 8vw;
      flex-flow: column nowrap;

      .db-footer-column {
        padding-bottom: 50px;
        width: 100%;

        &:first-child {
          padding-right: 0px;
          text-align: right;
        }

        &:last-child {
          padding-left: 0px;
          max-width: unset;
        }
      }
    }
  }

  .db-footer-copyright {
    line-height: 45px;
    background-color: var(--primary);
    color: var(--background);
    text-align: center;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 2px;
  }
}
